/* You can add global styles to this file, and also import other style files */

@import "~@ng-select/ng-select/themes/default.theme.css";

.mt-2{
    margin-top: 10px;
}
.mb-2{
    margin-bottom: 10px;
}
.mt-3{
    margin-top: 15px;
}
.w-auto{
    width: auto !important;
}
.text-right{
    text-align: -webkit-right !important;
    text-align: right;
}
.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
section.content-header{
    float: initial !important;
}
.btn-orange{
    background-color: #ff6f3c;
    border-color: #ff6f3c;
    color: #ffffff;
}
.btn-blue{
    background-color: #155263;
    border-color: #155263;
    color: #ffffff;
}
.btn-blue:hover, .btn-blue:focus, .btn-blue.focus,
.btn-orange:hover, .btn-orange:focus, .btn-orange.focus {
    color: #fff;
    text-decoration: none;

}
.btn{
    outline: none !important;
}
.mr-2{
margin-right: 10px;
}
.ml-2{
    margin-left: 10px;
}
.mr-3{
        margin-right: 15px;
 }
 .mt-4{
    margin-top: 20px;
}
.mt-5{
    margin-top: 25px;
}
 i.fa.fa-eye {
    color: #155263 !important;
}
i.fa.fa-pencil-square-o {
    color: #155263;
}
.skin-red .main-header .logo,
.skin-red .main-header .navbar{
    background-color: #073542 ;
}
.text-center th{
text-align: center !important;
}
.validation-error {
    color: red;
    position: absolute;
}
.nopadding{
    padding: 0 !important;
}
.nomargin{
    margin: 0 !important;
}label {
    margin-top: 7px;
}
.box,
.box.box-danger {
    border-top-color: #ff6f3c;
}
input{
    outline: none !important;
}
.d-block{
    display: block;
}
.d-inline-block{
    display: inline-block;
}
.pl-0{
padding-left: 0px;
}
.pr-0{
    padding-right: 0px;
}
.box-header {
    padding:15px 20px !important;
    background-color: #d9ebf4;
    display: flex;
    align-items: center;
}
.header-bg{
    background-color: #d9ebf4;
}
.overflow-hidden{
    overflow: hidden;
}
input,
select{
    border-radius: 4px !important;
    background-color:#d9ebf457 !important;
}
section.content .box-body{
    padding:10px 5px !important;
    margin-bottom: 35px;
}


.box-body{
    padding:10px 5px !important;    
    /* margin-bottom: 50px; */
}
section.content.user-permission .box.box-danger {
    padding: 10px 15px;
}
td>span{
    max-height: 102px;
    display: grid;
    overflow: auto;
    align-items: center;
}
td{
    max-height: 102px;
    overflow: auto;
    align-items: center;
}
.padd-left{
    padding-left: 0px;
}
.box-footer {
    padding: 10px 20px !important;
}
.swal2-popup{
    font-size: 16px !important;
}
#swal2-html-container {
    text-align: center;
}
.box, .box-danger{
    border-top-color: #155263 !important;
}
.modal-header {
    background: #ff6f3c59;
    padding: 12px 14px;
}
.modal-header .close span{
    font-size: 30px;
}
ul.ngx-pagination {
    padding-left: 0px ;
}

.treeview-menu a {
    cursor: pointer;
}
.btn-success{
    margin-right: 15px;
}
.main-footer {
    position: fixed;
    bottom: 0 !important;
    width: 100%;
}
.marginright15{
    margin-right: 15px;
}
button.btn.btn-secondary {
    background: #686767;
    color: #fff;
}
button.btn.btn-secondary:hover{
    background: #5a5a5aa8;
}
.box-footer .col-md-12 {
    display: flex;
    justify-content: end;
}

label.star {
    padding: 5px !important;
    font-size: 16px !important;
    color: #155263 !important;
  }
  .rattingmain{
    display: flex;
    align-items: center;
  }
  input.star-1:checked ~ label.star:before,
  input.star-2:checked ~ label.star:before,
  input.star-3:checked ~ label.star:before,
  input.star-4:checked ~ label.star:before,
  input.star-5:checked ~ label.star:before
   {
    color: #ff6f3c !important;
    text-shadow:none !important;
}
.d-flex{
    display: flex;
}
.align-items-center{
    align-items: center;
}
.justify-content-center{
    justify-content: center;
}
.justify-content-end{
    justify-content: end;
}
.justify-content-between{
    justify-content: space-between;
}
.gap-15{
gap: 15px;
}
.gap-10{
    gap: 10px;
}
.m-0{
    margin: 0px !important;
}

::-webkit-scrollbar {
    width: 6px;
    height: 8px;
}
::-webkit-scrollbar-thumb {
    background: #d9d9da;
    border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
    background: #155263;
}
.d-block {
    display: block !important;
}.skin-red .sidebar a {
    color: #f5fcff  !important;
}.skin-red  i.fa.fa-circle-o {
    font-size: 8px;
    text-align: left;
    margin-left: 16px;
}


/* veerpal css 29-09-2023 */

thead {
    background: #f1f7fb;
}
thead th {
    padding: 15px !important;
}.table-striped > tbody > tr:nth-of-type(even) {
    background-color:#e0ecef !important;
    
}.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #fff !important;
    
}td {
    padding: 10px 20px !important;
}.ngx-pagination .current {
   
    background: #0f3945 !important;
}
/* }tr td:nth-last-child(-n+2) {
    text-align: center !important;
 
} */


tr th:last-child{

    text-align: center !important;
}td.last-child {
    text-align: center;
}.user-permission .box.box-danger {
    padding: 10px 15px;
}.user-permission button {
    margin-right: 10px;
}section.content.user-permission form {
    padding: 0px 15px;
    margin-bottom: 10px;
}.user-permission-new .box.box-danger {
    padding: 7px 10px;
}.content-wrapper {
    padding: 20px 35px;
}form {
    padding: 10px 15px;
}.btn-primary:hover, .btn-primary:active, .btn-primary.hover {
    background-color: #538cad;
}
button{
    cursor: pointer !important;
}table {
    /* margin: 0px 15px 11px 15px !important; */
    width: 100% !important;
}

@media only screen and (max-width: 1300px) 
{
aside.control-sidebar.control-sidebar-dark {
    display: none;
}table {
    margin: 0px !important;
}
fieldset {
    min-inline-size: auto !important;
}
.report-text {
      
    width: 100%!important;
}
.report-img {
    width: 100%!important;
    /* width: 20%; */
}
 
}
@media only screen and (max-width: 991px) {
    .col-md-6.logo-cla {
        display: none !important;
    }
    .col-md-6.forgot-class{
        text-align: left !important;
    }

.forgot-password  label {
        margin: 0px !important;
    }
}


@media only screen and (max-width: 768px) {
    aside.control-sidebar.control-sidebar-dark {
        display: none;
    }nav.navbar.navbar-static-top {
        position: absolute;
        z-index: 999;
        width: 94%;
        cursor: pointer;
        right: 13px;
        top: -1px;
    }.form-group.col-md-4.padd-left {
        width: 100%;
    }
    

 
    .box-header {

        display: block;

    }.productimage img {
        width: 22% ! IMPORTANT;
    }


    td img {
		width: 100%!important;
	}

    button.btn.btn-icon {
        display: inline-block;
        padding: 2px 6px;
        margin-bottom: 0;
        font-size: 11px;
    }
    
    a.sidebar-toggle {
        display: block !important;
    }
    
    input#todate {
        margin-top: 8px;
        margin-bottom: 14px;
    }
    .content-wrapper {
        min-height: auto !important;
    }.col-md-12.mt-2.text-right.custom-button {
        margin-left: 21px;
        margin-top: 0px;
        width: auto;
    }
    .form-group.col-md-6 {
        float: left;
        width: 100%;
    }
    .col-lg-6.list-items {
        float: left;
        width: 100%;
    }
    
    .ng-select-container {
        margin-bottom: 12px;
    }
    .row {
        display: block;
    }
    .main-sidebar, .left-side {
        padding-top: 59px;
    }
    .main-footer {
        position: static;
        text-align: center;
      
    }.top-products ul {
      
        height: auto!important;
       
    }
    
    span.logo-lg {
        display: none !important;
    }.table-responsive {
        margin: 0 auto;
        width: 96%;
    }
    span.logo-mini {
        display: none !important;
    }.main-header {
        position: absolute;
    }.main-header .logo .logo-mini {
        display: block;
        text-align: left;
    }.wrapper {
        position: relative;
    }.content-wrapper {
        padding: 20px 25px;
        margin-bottom: 55px;
    }
  }

  section.content-header.padding-less {
    padding-left: 0px;
}.sidebar-menu .treeview-menu > li > a {
    padding: 5px 5px 5px 0px;
 
}section.content-header.sub-head {
    padding-left: 0px;
    margin-bottom: 9px;
}section.content.sub-cat {
    padding-left: 0px;
}aside.control-sidebar.control-sidebar-dark {
    display: none;
}span.logo-mini {
    background: #ff6f3c;
    /* color: #155263; */
    font-weight: bold;
}
.paddinglr5{
    padding: 5px !important;
}   
.fullwidthheight{
    width: 100% !important;
    height: 100% !important;
}


.my-search{
    padding: 10px 1px;
}button.btn.btn-danger.custom-button {
    margin: 10px 0px;
}
.my-search  input.form-control {
    border-color: #1552637a !important;
    border-radius: 5px 0px 0px 5px !important;
    background: #fff !important;
}
.my-search  button.btn.btn-outline-success {
    border-color: #1552637a;
    border-radius: 0px 5px 5px 0px;
    background: #155263bd;
    border-left: 0px;
    color: #fff;
}
.ml-15{
margin-left: 15px;
}
.colmanager25 .col-md-4{
    width: 25%;
}
.viewdetailmain .input-group {
    width: 100%;
}
.invocelabel{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 28px;
}
.invocelabel label {
    font-weight: 500 !important;
    margin: 0 !important;
}
.invocelabel input {
    margin: 0 !important;
}
.displaygap15 {
    display: flex;
    gap: 15px;
}
button,a{
    cursor: pointer;
}
.headingmanage{
    display: flex;
        align-items: center;
        gap: 50px;
}
.gap-5{
    gap: 5px;
}
.nomargin{
    margin: 0px;
}
.plusbutton{
    padding: 0px 5px !important;
}
.close{
    filter: invert(100%);
    opacity: 1;
}
.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    background-color: #155263;
    border-bottom: 0 solid #dee2e6;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
}
.modal-content{
    border-radius: 0.2rem;
    overflow: hidden;
}
.modal-header:after,
.modal-header:before{
    display: none !important;
}
.text-white{
    color: #fff !important;
}
.beforeafternone .box-header:before{
    display: none;
}
.beforeafternone .box-header:after{
    display: none;
}
.w-100{
    width: 100% !important;
}
.icon{
    width: 18px;
    height: 18px;
}
.displaygap10{
    display: flex;
    gap: 10px;
}
.addcolormain section.content-header.sub-head{
    display: none !important;
}
.addcolormain section.content.sub-cat{
    padding: 0 !important;
}
.addcolormain section.content.sub-cat .overflow-hidden .row{
    margin: 0 !important;
}
.main-header {
    z-index: 999;
}
span.form-control-feedback {
    pointer-events: visible !important;
    cursor: pointer !important;
} .add-color button.btn.btn-icon.m-1.p-1.mr-2 {
    background: transparent !important;
}.add-color ul.ngx-pagination.ng-star-inserted {
    margin-top: 16px!important;
}.add-color  .modal-content  .box.box-danger {
    border: none!important;
}.add-color .modal-content  .box {
    border: none!important;
}
.add-color   .modal-content   .box-body {
    /* border: none !important; */
    padding-top: 0px !important;
} .add-color  button.btn.btn-icon.m-1.p-1.mr-2 {
    background: transparent;
}.swal2-icon.swal2-warning {
   
    font-size: 10px;
}.buttons {
    margin-top: 31px !important;
    text-align: right!important;
}
pagination-controls {
    text-align: right !important;
}tr:hover {
    background: #dde1e7;
}table.table.table-striped th {
    padding-left: 20px !important;
}ul.dropdown-menu.dropy li:hover {
    background: #f1eded;
}
ul.dropdown-menu.dropy li a {
    color: #000;
}.table {
   
    margin-bottom: 0px !important;
}
.d-flex.justify-content-center button {
    /* background: transparent; */
}input {
    background: transparent !important;
    color: #000 !important;
}
select {
    background:transparent !important;
    color: #000 !important;
}textarea {
    background: transparent !important;
    color: #000 !important;
}
.ng-select.ng-select-disabled>.ng-select-container {
    background-color: #ecf0f5;
}
.readonlyfield 
{
    background-color: #ecf0f5;

}.badge-comnon {
    padding: 4px 10px !important;
}
.content {
 
    padding-right: 0px;
}.content-header {
   
    padding: 15px 15px 0 0px;
}
button.btn.btn-icon.m-1.p-1.mr-2 {
    background: transparent;
}
.margin-left-np {
    padding-left: 0px;
}
.box-header input {
    background: #fff !important;
}
td img {
    width: 50%;
}
.product-box .box-header input {
    background: transparent !important;
  }
  .view-user-box .box-header input {
    background: transparent !important;
  }
  .owl-theme .owl-nav {
    margin-top: 10px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    /* bottom: 22rem; */
}
button.owl-prev {
    position: absolute;
    left: 0;
        background: rgb(234 234 234) !important;
    color: #3f3f3f !important;
    /* padding: 10px !important; */
}
button.owl-next {
    position: absolute;
    right: 0;
        background: rgb(234 234 234) !important;
}
button.owl-prev:after {
    content: "\f177";
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    padding: 6px 7px;
    -webkit-font-smoothing: antialiased;
    font-size: 21px;
}
button.owl-next:after {
    content: "\f178";
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    padding: 6px 7px;
    -webkit-font-smoothing: antialiased;
    font-size: 21px;
}
.owl-theme .owl-nav [class*=owl-]:hover {

    color: #3f3f3f !important;

}
.owl-carousel .owl-item .item {
    width: 100%;
    height: 227px;
}
.owl-carousel .owl-item img {
    display: block;
    width: auto;
    height: 100% !important;
    margin: 0 auto !important;
}
.p-0{
    padding: 0px;
}
td.descrip-product div {
    height: 170px;
    overflow: auto;
}

*::-webkit-scrollbar {
    width: 2px;
}



*::-webkit-scrollbar-thumb {
   height: 10px;
    border-radius: 20px;

}
.modal-body .ng-dropdown-panel-items.scroll-host {
    overflow: scroll !important;
    height: auto;
    max-height: 160px !important;
}
input#SearchID {
    background: #fff !important;
} 
.ng-option {

    padding: 5px 10px !important;
}
div#largeModal3 {
    z-index: 1200;
}
fieldset {
    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 1px;
    border-style: groove;
    border-color: rgb(192, 192, 192);
    border-image: initial;
   
}.box-body {
    width: 100%;
}
legend {
    display: inline;
    padding-inline-start: 2px;
    padding-inline-end: 2px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    width: auto;
    margin-bottom: 0px;
    font-size: 20px;

}.no-data {
    text-align: center;
    padding: 20px;
}.no-data i.fa.fa-exclamation-circle {
    font-size: 51px;
    color: #ff6f3c;
}.no-data h4 {
    font-weight: bold;
}
.row.mt-3 .col-md-3 select.form-control.w-auto {
    display: none;
}
.row.lst .col-md-3 select.form-control.w-auto {
    display: none;
}
.row.mt-3 .col-sm-3 select.form-control.w-auto {
    display: none;
}
.row.mt-5 .col-sm-3 select.form-control.w-auto {
    display: none;
}
.swal2-popup.swal2-modal.swal2-icon-warning.swal2-show button {
    padding: 7px 16px!important;
    width: auto;
}
.swal2-popup.swal2-modal.swal2-icon-question.swal2-show button {
    padding: 7px 16px!important;
    width: auto;
}
.swal2-html-container 
{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-size: 16px !important;
    
    }

    .modal-body button.btn.btn-icon.mr-2 {
        padding: 1px 8px;
        font-size: 12px;
        font-weight: normal !important;
    }

    .main-sidebar{
        overflow: auto !important;
        max-height: 100% !important;
    }


    
    a.sidebar-toggle {
        display: none;
    }
    .swal2-title {
        font-size: 20px!important;
        margin-bottom: 0px!important;
        color: #0d313b!important;
    }
     .swal2-input {
        height: 35px!important;
        padding: 0 .75em!important;
        width: 135px!important;
        /* margin: 0 auto; */
        margin-bottom: 0px!important;
        font-size: 14px!important;
    
    }
    .swal2-styled {
        margin: 0px 5px!important;
        padding: 3px 12px!important;
        font-size: 15px !important;
        box-shadow: none!important;
        font-weight: 500!important;
    }
    
    div#swal2-validation-message {
        background: transparent;
        margin: 0px;
        font-size: 15px;
    }.modal {
        padding-top: 6%;
    }
    .product-online {
        display: flex;
        align-items: center;
        gap: 14px;
    }
    .ship-1 input {
        margin-right: 7px;
    }
    .box-header ul.dropdown-menu {
        padding: 10px;
    }.second-butt {
        margin-top: 10px;
    }
    .box-header select {
        text-align: left !important;
    }
    .box-header ul.dropdown-menu {
        right: 0px!important;
        left: auto!important;
    }button.swal2-confirm.swal2-styled {
        background: #297084 !important;
    }button.swal2-cancel.swal2-styled {
        background: #686767;
    }input#swal2-input {
        width: 100% !important;
    }.cash input {
        text-align: right;
    }


    /* new styles 26 april */

    .displaygap10 .btn .btn-icon{
        padding: 4px 8px !important;
    }
    span.cstm-required {
        color: red;
    }
    .btn-success {
        background-color: #297084 !important;
        border-color: #297084 !important;
        color: #fff !important;
    }

    .btn-success:hover{
        background-color: #266678 !important;
        border-color: #266678 !important;
    }
.margin-right-2{
    margin-right: 10px !important;
}
.btn-primary:hover {
    color: #fff;
    background-color: #266678 !important;
    border-color: #266678 !important;
}
.btn-primary {
    background-color:#297084 !important;
    border-color: #297084 !important;
    color: #fff !important;
}
.starred form {
    display: flex !important;
    flex-direction: row-reverse;
}
.pb-0{
    padding-bottom: 0px;
}
label{
    text-transform: capitalize !important;
}
.search-box-new {
    width: 100%;
}.report-text {
    display: flex;
    flex-direction: column;
}
.table-striped > tbody > tr:nth-of-type(odd):hover {
    background: #e9e9e9  !important;
}
.table-striped > tbody > tr:nth-of-type(even):hover {
    background: #e9e9e9  !important;
}
.badge-success {
    color: #fff;
    background-color: #28a745;
}
.badge-warning {
    color: #212529;
    background-color: #ffc107;
}
.badge-info {
    color: #fff;
    background-color: #17a2b8;
}
.badge-secondary {
    color: #fff;
    background-color: #6c757d;
}